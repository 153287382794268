<template functional>
  <div class="h-screen w-screen flex flex-wrap items-center justify-center">
    <div class="font-light text-tg-light-text">
      <router-link
        :to="{ name: 'Dashboard' }"
        class="text-xl md:text-2xl underline opacity-84 hover:opacity-100"
      >
        <i class="fal fa-arrow-left mr-2" /> Dashboard
      </router-link>
      <h1 class="font-normal">{{ props.status }}</h1>
      <p class="text-2xl md:text-4xl opacity-84">{{ props.message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: {
    status: {
      type: [Number, String]
    },
    message: {
      type: String
    }
  }
};
</script>

<style scoped>
h1 {
  font-size: 150px;
}
@screen md {
  h1 {
    font-size: 300px;
  }
}
</style>
